<template>
  <div>
    <div class="vx-row mt-5 items-center">
      <div class="vx-col lg:w-1/5 w-full">
        <label>
          Choose Class
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col lg:w-4/5 w-full mt-3">
        <vs-select
          class="w-full select-large"
          name="week_start_day"
          v-validate="'required'"
        >
          <vs-select-item class="w-full" />
        </vs-select>
      </div>
    </div>
    <div class="vx-row mt-5 items-center">
      <div class="vx-col lg:w-1/5 w-full">
        <label>
          Questions
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col lg:w-4/5 w-full mt-3">
        <vs-select
          class="w-full select-large"
          name="week_start_day"
          v-validate="'required'"
        >
          <vs-select-item class="w-full" />
        </vs-select>
      </div>
    </div>
    <div class="vx-row mt-5 items-center">
      <div class="vx-col lg:w-1/5 w-full">
        <label>
          The Answers types is Digital answers ?
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col lg:w-4/5 w-full mt-3">
        <ul class="con-s">
          <li>
            <vs-switch v-model="DigitalExam">
              <span slot="on">Yes</span>
              <span slot="off">NO</span>
            </vs-switch>
          </li>
        </ul>
      </div>
    </div>
    <div class="vx-row mt-5 items-center">
      <div class="vx-col lg:w-1/5 w-full">
        <label>
          Grading Type
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col lg:w-4/5 w-full mt-3">
        <vs-select
          class="w-full select-large"
          name="gradingType"
          v-validate="'required'"
          v-model="gradingTypeSelected"
        >
          <vs-select-item
            class="w-full"
            :key="`gradingType-${index}`"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in gradingType"
          />
        </vs-select>
      </div>
    </div>

    <div class="vx-row mt-5 items-center">
      <div class="vx-col lg:w-1/5 w-full">
        <label>
          Choose Date

          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col lg:w-4/5 w-full mt-3">
        <flat-pickr class="w-full" name="end_date" v-validate="'required'" />
        <span class="text-danger text-sm text-center">{{
          $t("start date should be more than end date with one year")
        }}</span>
        <span class="text-danger text-sm text-center">{{
          errors.first("end_date")
        }}</span>
      </div>
    </div>

    <div class="vx-row mt-5 items-center">
      <div class="vx-col lg:w-1/5 w-full">
        <label>
          Choose Time “Period”
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col lg:w-4/5 w-full mt-3">
        <vs-select
          class="w-full select-large"
          name="gradingType"
          v-validate="'required'"
          v-model="Time"
        >
          <vs-select-item
            class="w-full"
            :key="`time-${index}`"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in Timelist"
          />
        </vs-select>
      </div>
    </div>

    <div class="vx-row mt-5 items-center">
      <div class="vx-col lg:w-1/5 w-full"></div>
      <div class="vx-col lg:w-4/5 w-full mt-3">
        <div class="vx-row">
          <div class="vx-col lg:w-1/3 mt-3">
            <vs-select
              class="w-full select-large"
              name="gradingType"
              v-validate="'required'"
              v-model="Time"
            >
              <vs-select-item
                class="w-full"
                :key="`time-${index}`"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in Timelist"
              />
            </vs-select>
          </div>
          <div class="vx-col lg:w-1/3 mt-3">
            <vs-select
              class="w-full select-large"
              name="gradingType"
              v-validate="'required'"
              v-model="Time"
            >
              <vs-select-item
                class="w-full"
                :key="`time-${index}`"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in Timelist"
              />
            </vs-select>
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row mt-5 items-center">
      <div class="vx-col lg:w-1/5 w-full"></div>
      <div class="vx-col lg:w-4/5 w-full mt-3">
        <div class="vx-row">
          <div class="vx-col lg:w-1/3 mt-3">
            <vs-input class="inputx w-full" placeholder="HH" />
          </div>
          <div class="vx-col lg:w-1/3 mt-3">
            <vs-input class="inputx w-full" placeholder="MM" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    flatPickr
  },
  data () {
    return {
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true
      },
      value: null,
      QuestionsAnswersTypes: [
        { value: 1, text: 'Digital Exam' },
        { value: 2, text: 'PDF Exam' },
        { value: 3, text: 'Given Paper Exam' },
        { value: 4, text: 'Oral Exam' }
      ],
      DigitalExam: true,
      QuestionsAnswersType: null,
      gradingType: [
        { value: 1, text: 'Auto Grading' },
        { value: 2, text: 'Manual grading' }
      ],
      gradingTypeSelected: 1,
      Timelist: [
        { value: 1, text: 'Fixed time' },
        { value: 2, text: 'Fixed Period' },
        { value: 3, text: 'Open time' }
      ],
      Time: 1,
      ChooseSubject: [
        { value: 1, text: 'label 7' },
        { value: 2, text: 'label 8' },
        { value: 3, text: 'label 9' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>